.body {
    width: 100%;
    margin: 0 auto;
    padding: 0 2.8rem;
}

.title {
    text-align: center;
    margin-top: 6rem;
    margin-bottom: 4rem;
}

.form {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 2rem;
}

.elem {
    width: 24%;
    position: relative;
}

.input {
    padding: 0.5rem 1rem;
    border: 0.1rem solid #d9d9d9;
    border-radius: 0.5rem;
}

.delete {
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    position: absolute;
    right: 0;
    height: 3.2rem;
}

.deleteBtn {
    color: #BDBDBD;
}

.addWord, .saveWords {
    width: 100%;
    max-width: 45rem;
    display: block;
    margin: 3rem auto 0;
    background: linear-gradient(94.25deg, #007BE0 -0.43%, #0058A0 54.69%);
    border: none;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    font-size: 1.6rem;
    color: #FFFFFF;
    cursor: pointer;
}

.saveWords {
    margin-bottom: 3rem;
}

.modalButtons {
    display: flex;
    justify-content: space-between;
}

.modalButtons button {
    border-radius: 6px;
}