.oauth {
    margin: 0 auto;
    padding: 0 15px;
}

.oauthHeader {
    margin-top: 50px;
    margin-bottom: 20px;
}

.title {
    text-align: center;
    font-size: 28px;
}

.oauthBody {
    margin-bottom: 25px;
}