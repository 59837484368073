.update_modal_row {
  margin-top: 10px !important;
}

.update_modal_input {
  border-radius: 10px !important;
  min-width: 270% !important;
}

.update_modal_cancel_button {
  margin-top: 15px;
  width: 49.2%;
  border-radius: 10px !important;
}

.update_modal_add_button {
  margin-top: 15px;
  margin-left: 10px;
  width: 49.2%;
  border-radius: 10px !important;
}

.ant-select-selector {
  border-radius: 10px !important;
}
