.block {
    width: 100%;
    margin: 0 auto;
    padding: 0 2.8rem;
}

.title {
    text-align: center;
}

.block .btn {
    width: 100%;
    max-width: 45rem;
    height: auto;
    padding: 1rem 1.5rem;
    font-size: 1.6rem;
}

.block .input {
    max-width: 300px;
    width: 100%;
}

.users {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.user {
    border: 1px solid #cccccc;
    padding: 5px;
}

.user p {
    margin: 0;
}

.titleWarning {
    color: #ff4d4f;
}

.btnSave {
    margin-bottom: 15px;
}
