.avatarText:global(.ant-typography) {
    font-size: 1.4rem;
    line-height: 1.428;
    color: #909EBB;
    margin-top: 1.8rem;
    display: block;
    text-align: center;
}

.ava {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

._avatarCenter {
    display: flex;
    justify-content: center;
}


@media screen and (max-width: 892px) {
    ._marginTop100 {
        margin-top: 1rem;
    }
}
