@import '~antd/dist/antd.css';

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 10px;
}

.ant-input-affix-wrapper,
.ant-picker {
    padding: 0.8rem 1.5rem;
    border-radius: 0.4rem;
    outline: none;
    transition: .3s;
    width: 100%;
    border: 0.1rem solid #E4E7F2;
}

.ant-input,
.ant-picker-input>input {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.25;
    color: #3B4168;
    width: 100%;
}

#filter .ant-input {
    height: 32px;
}

.ant-btn {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.25;
}

.ant-btn-ghost {
    border: 0.1rem solid #0071CE;
    border-radius: 0.4rem;
    min-height: 4.4rem;
    padding: 0 2.4rem;
    color: #0071CE;
    transition: none;
}

.ant-btn-ghost:hover {
    background: #F5FBFF;
    border: 0.1rem solid #005EAC;
    color: #005EAC;
}

.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover {
    background: #FFFFFF;
    border: 0.1rem solid #BDBDBD;
    color: #BDBDBD;
}
/* 0-------------------------------------------------------- */
.app {
    height: calc(100vh - 192px);
}

.ant-btn {
    transition: none;
}

.ant-btn-link {
    color: #0071CE;
}

.ant-btn-link:hover {
    color: #005EAC;
}


.ant-btn-primary {
    background: linear-gradient(94.25deg, #007BE0 -0.43%, #0058A0 54.69%);
    border: none;
}

.ant-btn-primary:hover {
    background: #005EAC;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover {
    background: #BDBDBD;
    color: #ffffff;
}

.auth {
    width: 67rem;
    max-height: 66rem;
    padding: 10.5rem;
    margin-top: 14.5rem;
    border-radius: 1.5rem;
    background-color: #ffffff;
}

.auth__title  {
    font-family: 'Montserrat', sans-serif;
    font-size: 3.2rem;
    line-height: 1.25;
    color: #3B4168;
    font-weight: 700;
    text-align: center;
    /* margin-bottom: 5.5rem; */
}

.auth__tab-pane {
    font-size: 30rem;
}

.auth .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    padding: 1rem 1.5rem;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 1.33;
    font-family: 'Montserrat', sans-serif;
    color: #BDBDBD;
}

.auth .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0071CE;
}

.auth .ant-tabs-ink-bar {
    background: #0071CE;
}

.auth .ant-tabs-nav {
    margin-bottom: 5.4rem;
}

.auth .ant-input {
    font-size: 1.6rem;
    line-height: 1.25;
    color: #3B4168;
    text-align: center;
}

.auth .ant-input-affix-wrapper > input.ant-input {
    padding-left: 1.6rem;
}

.auth .ant-input-affix-wrapper > input[type=password] {
    padding-left: 4rem;
}

._clickLing {
    display: flex;
    justify-content: center;
    width: 200px;
    font-size: 17px;
    color: grey;
    cursor: pointer;
    border-bottom: 2px solid grey;
}

._clickLing:hover {
    display: flex;
    justify-content: center;
    width: 200px;
    font-size: 17px;
    color: grey;
    cursor: pointer;
    border-bottom: 2px solid grey;
}

@media screen and (max-height: 1000px) {
    .app {
        height: 100%;
    }
}


@media screen and (max-width: 670px) {
    .auth {
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 5rem;
        padding: 7.5rem 2.5rem;
    }
}