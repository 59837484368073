._avatarModal :global(.ant-modal-content) {
    padding: 3.2rem;
}

._avatarModal :global(.ant-modal-header) {
    padding: 0 0 1.6rem;
    border-bottom: 0.1rem solid #E4E7F2;
    margin-bottom: 2.4rem;
}

._avatarModal :global(.ant-modal-close) {
    top: 2rem;
    right: 2.5rem;
}

._avatarModal :global(ant-modal-close-x) {
    width: 4rem;
    height: 4rem;
    font-size: 1.4rem;
    line-height: 1;
}

._avatarModal :global(.anticon) svg {
    width: 2rem;
    height: 2rem;
    color: #000000;
}

._avatarModal :global(.ant-modal-title) {
    color: #3B4168;
    font-size: 2.4rem;
    line-height: 1.166;
    font-weight: 600;
}

._avatarModal :global(.ant-modal-body) {
    padding: 0;
    margin-bottom: 2.4rem;
}

._avatarModal :global(.ant-modal-footer) {
    padding: 2.4rem 0 0;
    border-top: 0.1rem solid #E4E7F2;
}

.avatarLabel {
    height: 100%;
    display: block;
}

.inputStyle {
    display: none;
}

.labelButton {
    border-radius: 0.4rem;
    border: 0.1rem solid #0071CE;
    color: #0071CE;
    padding: 1rem 2.4rem;
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.25;
    min-height: 4.4rem;
    display: block;
    cursor: pointer;
}

.labelButton:hover {
    background: #F5FBFF;
    border: 0.1rem solid #005EAC;
    color: #005EAC;
}

.avatarDasher {
    border: 0.2rem dashed #E4E7F2;
    height: 100%;
}

._avatarHaveImage {
    border-style: hidden;
}

._avatarHaveImage :global(.reactEasyCrop_CropArea) {
    color: rgba(0, 0, 0, 0.3);
}

._borderNotImage {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

._textBorder {
    display: flex;
    align-items: center;
}

._textBorder :global(.ant-typography) {
    font-size: 1.6rem;
    line-height: 1.25;
    color: #6F7C98;
}

._textBorder :global(.ant-typography) span {
    color: #0071CE;
    cursor: pointer;
}

._footerButton_Body {
    display: flex;
    justify-content: space-between;
}

._buttonAnother {
    margin-top: 0.5rem;
    margin-right: 1rem;
}

._paperClip {
    margin-right: 4rem;
}

._paperClip:global(.anticon) svg {
    width: 3.2rem;
    height: 3.2rem;
}

@media screen and (max-width: 640px) {
    ._footerButton_Body {
        display: flex;
        justify-content: center;
    }


}

@media screen and (max-width: 420px) {
    ._footerButton_Body {
        display: flex;
        flex-wrap: wrap;
    }

    ._buttonAnother {
        margin-bottom: 10px;
    }

    ._textBorder {
        font-size: 15px;
    }
}