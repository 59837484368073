.prefix {
  width: 1.693rem;
  height: 1.693rem;
}

.ban_search {
  width: 340px;
}

.ban_list {
  margin-top: 20px !important;
}

.ban_container {
  display: flex;
  justify-content: space-between;
}

.ban_form {
  display: flex;
  margin-top: 10px;
  width: 270px;
}

.hours_title {
  margin-top: 20px;
}

.time_input {
  max-height: 40px;
  margin-right: 20px !important;
}

.submit_button {
  min-width: 92.5%;
  margin-top: 30px;
  height: 40px !important;
}

.selected {
  background: rgba(208, 208, 208, 0.85);
}

.arrow_left {
  border: solid #BDBDBD;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transition: 0.2s linear;
}

.arrow_right {
  border: solid #BDBDBD;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transition: 0.2s linear;
}

.arrow {
  margin-top: 10px;
  display: flex;
  margin-left: 20px;
}

.arrow p {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.3rem;
  cursor: pointer;
}

.paginationCounter {
  position: relative;
  width: 20px;
  height: 22px;
  border: 0.5px solid rgb(195, 195, 195);
  margin-top: 0.5rem;
  border-radius: 5px;
  text-align: center;
}

.count_all {
  margin-top: 15px;
}

.pagination_inline {
  display: flex;
}

.hours_select {
  width: 100%;
  margin-right: 20px !important;
}

.ant-modal-body {
  padding-top: 0px !important;
}

.country_ban_container {
  margin-top: 20px;
}

.country_ban {
  display: flex;
  justify-content: space-between;
}

.country_ban_input {
  width: 100%;
}

.submit_button_country {
  margin-left: 20px;
  width: 100%;
}

.unban_button {
  width: 100%;
}