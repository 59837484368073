.header {
  position: relative;
  width: 100vw;
  min-height: 650px !important;
}

.header_input {
  border-radius: 10px !important;
  height: 35px;
}

.tableThead th {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  background: #fff;
  padding: 1.6rem 11.27rem;
}

.edit_delete_column {
  display: inline-flex;
  margin-left: 10px;
}

.edit_button {
  border-radius: 5px !important;
}

.delete_button {
  margin-left: 10px;
  border-radius: 5px !important;
}

.add_button {
  width: 98%;
  margin-left: 13px;
}

.add_button div {
  margin-top: 7px;
}

.title {
  text-align: center;
}