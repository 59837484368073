.profile__wrapper {
    min-width: 90rem;
    display: flex;
    justify-content: center;
}

._body {
    margin: 2rem;
    width: 100%;
    max-width: 68rem;
}

._title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #3B4168;
    margin-bottom: 1.2rem;
    font-size: 2.8rem;
    line-height: 1.285;
}

._description {
    margin-bottom: 3.6rem;
    font-size: 1.4rem;
    line-height: 1.428;
    color: #909EBB;
}

._description--margin {
    margin-bottom: 2.4rem;
}

.flexWidth :global(.ant-col) {
    flex: 1
}

.marTop {
    margin-top: 3.2rem;
}

._password {
    align-items: center;
}

._checkBox {
    margin-bottom: 2rem;
}

._checkBox span {
    color: #909EBB;
}

.flexWidthHalf {
    width: calc(50% - 1.1rem);
}

.actions__btn {
    display: flex;
    align-items: center;
}

.actions__btn__right {
    margin-left: 3.2rem;
}

._patronymic {
    margin-bottom: 7px;
}

._phoneModalBody :global(.ant-form-item-control) {
    width: 100%;
}

.inputBlock {
    width: 100%;
}

._modalText{
    color: #6F7C98;
    font-size: 1.6rem;
    max-width: 45rem;
    margin-bottom: 3.2rem;
    margin-top: 3.6rem;
    line-height: 1.6;
}

._modalTitle{
    color: #3B4168;
    font-size: 2.4rem;
    font-family: 'Montserrat', sans-serif;
    line-height: 2.4;
}

._line{
    width: 100%;
    border-bottom: 0.1rem solid #E4E7F2;
    margin: 1.2rem 0 4rem;
}

._modalLine {
    border-bottom: 0.1rem solid #E4E7F2;
    margin: 1.2rem 0 2.4rem;
}

.changeNumModal :global(.ant-modal-body){
    padding: 0;
}

.changeNumModal :global(.ant-modal-footer){
    border-top: 0.1rem solid #E4E7F2;
    padding: 1.6rem 0 0;
    margin-top: 3.6rem;
}

.changeNumModal :global(.ant-modal-content){
    padding: 3.2rem;
}

._modalBtnCancel{
    margin-right: 1.6rem;
    width: 12.3rem;
}

._modalBtnSave {
    margin-left: 0!important;
}

.changeNumModal :global(.ant-modal-close-x){
    font-size: 2.5rem;
    margin: 3.3rem 3.8rem 0 0;
    color: #000000;
}

.changeNumModal :global(.ant-form-item-label label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before ){
    margin: 0.5rem 0 0 0 !important;
}

.changeNumModal :global(.ant-form-item-label label.ant-form-item-no-colon::after){
    display: none;
}

.btnSearchAddress {
    min-height: 4rem;
    margin-left: 2.2rem;
}

.addressSelect :global(.ant-select-selection-placeholder) {
    font-size: 1.6rem !important;
    color: #909EBB !important;
}

.addressSelect :global(.ant-select-selector) {
    align-items: center !important;
    border: 0.1rem solid #E4E7F2 !important;
    border-radius: 0.4rem !important;
    height: 4rem !important;
}

.addressSelect :global(.ant-select-selection-search-input) {
    height: 100% !important;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.25;
    color: #3B4168;
}

.addressSelect :global(.ant-select-selection-item) {
    height: 100% !important;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.25!important;
    color: #3B4168;
}

.addressForm :global(.ant-form-item-control-input-content) {
    display: flex;
}

.footerBlock{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.successModal :global(.ant-modal-content){
    width: 49.2rem;
    height: 32.1rem;
}

.successModal :global(.ant-modal-close){
    display: none;
}

.successModal :global(.ant-modal-footer){
    display: none;
}

.successModal :global(.ant-modal-body){
    padding: 4rem 3.2rem;
}

.successModalText{
    color: #909EBB;
    font-size: 1.4rem;
    line-height: 1.25;
    font-weight: 400;
    font-style: normal;
}

.successModal{
    text-align: center;
}

.successModalTitle{
    margin-top: 3.2rem;
    margin-bottom: 1.2rem;
    color: #3B4168;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 1.2;
}

._modalBodyBlock{
    text-align: center;
}
.pinInputBlock{
    width: 100%;
    display: flex;
    justify-content: center;
}
.pinInputBlock :global(.kfAyjk input){
    margin: 0 0.75rem;
}

.emailText{
    color: #0071CE;
}

.hocMaskedError :global(.ant-form-item-has-error .ant-input-affix-wrapper) {
    border: 1px solid #ff4d4f;
}

.hocMaskedError :global(.ant-form-item-has-error .ant-input-affix-wrapper-focused) {
    border-color: #ff7875;
    box-shadow: 0 0 0 0.2rem rgba(255, 77, 79, 0.2);
    border-right-width: 0.1rem;
    outline: 0;
}

.hocMaskedError :global(.ant-form-item-has-error .anticon-close-circle) {
    color: #f94c4c;
    font-size: 1.4rem;
}

.datePickerInputBlock{
    height: 4.2rem;
}

.datePickerInput{
    width: 32.9rem;
    height: 4rem;
    border: 1px solid #E4E7F2;
    border-radius: 0.4rem;
    padding: 0.8rem 1.5rem;
    font-size: 1.6rem;
    transition: 0.2s linear;
}

.datePickerInput:hover{
    border: 0.1rem solid #71baff;
    transition: 0.2s linear;
}

.errorText{
    color: red;
}

.errorLine{
    border: 0.1rem solid red !important;
}

.datePickerInputBlock :global(.react-datepicker){
    width: 28rem;
    height: 30.5rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
    border: none;
    border-radius: 0;
}

.datePickerInputBlock :global(.react-datepicker__close-icon::after){
    background-color: #bebec1;
}

::-webkit-input-placeholder {
    color: #909EBB;
}

.datePickerInputBlock :global(.react-datepicker__input-container button){
    margin-right: 1rem;
}

.datePickerInputBlock :global(.react-datepicker-popper){
    padding-top: 0 !important;
}

.datePickerInputBlock :global(.react-datepicker__current-month){
    padding: 1.1rem 0;
    font-size: 1.4rem;
    border-bottom: 1px solid #f0f0f0;
}

.datePickerInputBlock :global(.react-datepicker__navigation){
    margin-top: 1.8rem;
}

.datePickerInputBlock :global(.react-datepicker__header){
    width: 27.8rem;
    background-color: #fff;
    border-bottom: none;
}

.datePickerInputBlock :global(.react-datepicker__triangle){
    display: none;
}

.datePickerInputBlock :global(.react-datepicker__day){
    width: 3rem;
    height: 3rem;
    padding-top: 0.7rem;
    margin: 0.25rem;
    font-size: 1.4rem;
}

.datePickerInputBlock :global(.react-datepicker__day-name){
    width: 3rem;
    height: 3rem;
    padding-top: 0.7rem;
    margin: 0.25rem;
    font-size: 1.4rem;
}

.datePickerInputBlock :global(.react-datepicker__day--selected){
    background-color: #1890ff;
}

.checkCircleIcon{
    color: #52c41a !important;
    position: absolute;
    top: 1.3rem;
    right: 1.5rem;
}

.closeCircleIcon{
    color: #ff4d4f;
    position: relative;
    top: -3.3rem;
    left: 13rem;
}

.datePickerInputBlock :global(.react-datepicker__close-icon::after){
    background-color: #bebec1;
    position: relative;
    right: 2.3rem;
    top: -0.05rem;
    width: 1.2rem;
    height: 1.2rem;
    padding: 0;
    transition: 0.1s linear;
}


.datePickerInputBlock :global(.react-datepicker__close-icon:hover::after){
    background-color: #949496;
    transition: 0.1s linear;
}

.defaultLine:focus{
    outline: 0;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #d8ecfe;
}

.innBlock {
    position: relative;
}

.errorLine:focus{
    outline: 0;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 77, 79, 0.2);
}

.errorsInn{
    color: red;
    position: absolute;
    top: 7rem;
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    line-height: 1.5715;
}

.notError{
    height: 0.2rem;
    width: 1rem;
}

.errorLineInn{
    border: 0.1rem solid red !important;
    border-radius: 0.4rem !important;
    transition: box-shadow 0.6s;
}

.errorInnBlock :global(.ant-select-focused){
    outline: 0;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #faa2a1 !important;
    border: 0.1rem solid red !important;
    transition: box-shadow 0.6s;
}

._body :global(.ant-form-item-label .ant-form-item-required) {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.428;
    color: #3B4168;
}

._body :global(.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before) {
    color: #0071CE !important;
    position: absolute;
    margin: 0 !important;
    top: 7%;
    right: -0.7rem;
}

.checkboxes{
    display: flex;
    column-gap: 34px;
}

._checkBox {
    margin-bottom: 2rem;
}

._checkBox span {
    color: #909EBB;
}

.checkable_block {
    margin-bottom: 24px;
}

.checkable_block :global(.ant-tag-checkable) {
    height: 3rem !important;
    border-radius: 2rem !important;
    border: 1px solid #0071CE;
    color: #0071CE;
    padding: 0.3rem 1.5rem ;
    margin: 0.5rem;
    font-size: 1.6rem;
    font-weight: 400;
}

.checkable_block :global(.ant-tag-checkable-checked) {
    color: white !important;
}

.btnDeleteRole {
    margin-left: 15px;
}


@media screen and (min-width: 576px) {
    ._body :global .ant-col:not(:last-child) {
        margin-right: 2.2rem;
        padding-bottom: 0.4rem;
    }
}

@media screen and (max-width: 980px) {
    .datePickerInput {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .profile__wrapper {
        flex-direction: column;
        margin-top: 5.5rem;
    }

    ._body {
        padding: 0 3.2rem;
        margin: 3.2rem auto 0;
    }

    ._title {
        text-align: center;
    }

    ._description {
        text-align: center;
    }

    .flexWidth :global(.ant-col) {
        flex-basis: 100%;
    }

    .position {
        width: 100%;
    }

    ._body :global .ant-col:not(:last-child) {
        margin-right: 0;
    }

    ._password :global(.ant-col) {
        flex: 1;
    }

    ._password :global .ant-col:first-child {
        margin-right: 2rem;
    }

    ._description--margin {
        text-align: left;
    }

    .hocMaskedError {
        flex-basis: 100%;
        max-width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .addressForm :global(.ant-form-item-control-input-content) {
        flex-direction: column;
    }

    .addressSelect {
        margin-bottom: 1.6rem;
    }

    .btnSearchAddress {
        margin-left: 0;
        background: transparent;
        border: 0.1rem solid #0071CE;
        color: #0071CE;
    }

    .flexWidthMobile :global(.ant-form-item) {
        margin-bottom: 0.6rem;
    }

    .description_file {
        padding: 2.4rem 3.5rem;
    }

    .flexWidthHalf {
        width: 100%;
    }

    ._password :global(.ant-col) {
        flex-basis: 100%;
    }

    ._password :global .ant-col:first-child {
        margin-right: 0;
    }
}
