.phone_auth_container {
  position: relative;
  width: 100vw;
  min-height: 650px !important;
}

.title {
  text-align: center;
}

.edit_button {
  border-radius: 5px !important;
  margin-left: 20px;
}

.over_auth {
  color: red;
}

.add_button {
  width: 98%;
  margin-left: 13px;
  margin-bottom: 20px;
}

.edit_delete_column {
  display: inline-flex;
  margin-left: 20px;
  margin-bottom: 20px;
}

.arrow_left {
  border: solid #BDBDBD;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transition: 0.2s linear;
}

.arrow_right {
  border: solid #BDBDBD;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transition: 0.2s linear;
}

.arrow {
  margin-top: 10px;
  display: flex;
  margin-left: 20px;
}

.arrow p {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.3rem;
  cursor: pointer;
}

.paginationCounter {
  position: relative;
  width: 20px;
  height: 22px;
  border: 0.5px solid rgb(195, 195, 195);
  margin-top: 0.5rem;
  border-radius: 5px;
  text-align: center;
}

.norm_input {
  border-radius: 5px !important;
  margin-left: 5px !important;
  width: 50px !important;
}

.unban_button {
  width: 100%;
}